import { IAction } from 'actions/commonAction';
import { PROCESS_COVERAGE_LEVEL_FOR_REIMBURSEMENT_ACTION } from 'actions/householdMember/processCoverageLevelForReimbursement';
import { APPLY_SHOPPING_COVERAGE_CHANGES } from 'actions/householdShoppingCoverage/applyShoppingCoverageChanges';
import { SET_INITIAL_REIMBURSEMENT } from 'actions/householdShoppingCoverage/setInitialReimbursement';
import { IHouseholdMemberDto, IHouseholdMemberPatchRecalculateDto } from 'api/generated/models';

type IHouseholdShoppingCoverageState = {
    householdMembersChanged: Record<string, IHouseholdMemberDto>;
    reimbursement: number;
};

const initialState: IHouseholdShoppingCoverageState = {
    householdMembersChanged: {},
    reimbursement: 0,
};

export const householdShoppingCoverage = (
    state = initialState,
    action: IAction<IHouseholdMemberPatchRecalculateDto>
): IHouseholdShoppingCoverageState => {
    if (action.type === SET_INITIAL_REIMBURSEMENT) {
        const { reimbursement } = (action.data as unknown) as { reimbursement: number };
        return {
            reimbursement,
            householdMembersChanged: {},
        };
    } else if (action.type === PROCESS_COVERAGE_LEVEL_FOR_REIMBURSEMENT_ACTION.success) {
        return {
            householdMembersChanged: {
                ...state.householdMembersChanged,
                [action.data.householdMember?.householdMemberId ?? '']: action.data
                    .householdMember as IHouseholdMemberDto,
            },
            reimbursement: action.data.activeWageUp?.reimbursement ?? 0,
        };
    } else if (action.type === APPLY_SHOPPING_COVERAGE_CHANGES) {
        return initialState;
    }
    return state;
};
