import { RoleIds, ROLE_IDS } from 'api/generated/roleIds';
import { AppStore } from 'reducers/appReducer';
import { createSelector } from 'reselect';

export const isInRoleSelector = (state: AppStore, roleId: RoleIds): boolean =>
    state.login.ur?.some((ur) => ur.toLowerCase() === roleId.toLowerCase());
export const isMemberSelector = (state: AppStore) => isInRoleSelector(state, ROLE_IDS.MEMBER);
export const isAdvisorSelector = (state: AppStore) => isInRoleSelector(state, ROLE_IDS.ADVISOR);
export const isTeamAdminSelector = (state: AppStore) =>
    isInRoleSelector(state, ROLE_IDS.TEAM_ADMIN);
export const isTeamPayrollAdminSelector = (state: AppStore) =>
    isInRoleSelector(state, ROLE_IDS.TEAM_PAYROLL_ADMIN);
export const isRhFullAdminSelector = (state: AppStore) =>
    isInRoleSelector(state, ROLE_IDS.RH_FULL_ADMIN);
export const isRhTeamMemberSelector = (state: AppStore) =>
    isInRoleSelector(state, ROLE_IDS.RH_TEAM_MEMBER);
export const isRhSelector = createSelector(
    isRhFullAdminSelector,
    isRhTeamMemberSelector,
    (isFullAdmin, isRhTeamMember) => isFullAdmin || isRhTeamMember
);
export const isCcmAdminSelector = (state: AppStore) => isInRoleSelector(state, ROLE_IDS.CCM_ADMIN);
export const isUserInRoleSelector = (
    state: AppStore,
    userId: string | undefined,
    roleId: RoleIds
): boolean =>
    state.teamUserRoles.some((ur) => ur.userId === userId && ur.roleIds?.includes(roleId));
