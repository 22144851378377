import { HouseholdEligibilityStatus } from 'api/generated/enums';
import { flagsToValuesArray } from 'utilities';

export const isEligible = (
    status: HouseholdEligibilityStatus,
    eligibility: HouseholdEligibilityStatus
) => {
    const test = status & eligibility;
    return test === eligibility;
};

export const getEligibilityData = (eligibilityStatus: HouseholdEligibilityStatus | undefined) =>
    flagsToValuesArray(eligibilityStatus, HouseholdEligibilityStatus);
