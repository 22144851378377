import { IAction } from 'actions/commonAction';
import { CLEAR_PATHWAY_MARKETPLACE_PLAN_MIGRATIONS } from 'actions/pathwayBlueprint/clearPathwayMarketplacePlanMigrations';
import { GET_PATHWAY_MARKETPLACE_PLAN_MIGRATIONS_ACTION } from 'actions/pathwayBlueprint/getPlansForNextYearAsync';
import { IPathwayMarketplacePlanMigration } from 'api/generated/models';

const initialState: IPathwayMarketplacePlanMigration[] = [];

export const pathwayMarketplacePlanMigrations = (
    state = initialState,
    action: IAction<IPathwayMarketplacePlanMigration[]>
): IPathwayMarketplacePlanMigration[] => {
    if (action.type === GET_PATHWAY_MARKETPLACE_PLAN_MIGRATIONS_ACTION.success) {
        return action.data;
    } else if (action.type === CLEAR_PATHWAY_MARKETPLACE_PLAN_MIGRATIONS) {
        return [];
    }

    return state;
};
