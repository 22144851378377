import { GET_ADP_USER_CONFIGURATIONS_ACTION } from 'actions/adp/getAdpUserConfigurations';
import { IAction } from 'actions/commonAction';
import { IAdpUserConfigurationDto } from 'api/generated/models';

const initialState: IAdpUserConfigurationDto[] = [];

export const adpUserConfigurations = (
    state = initialState,
    action: IAction<IAdpUserConfigurationDto[]>
): IAdpUserConfigurationDto[] => {
    if (action.type === GET_ADP_USER_CONFIGURATIONS_ACTION.success) {
        return action.data;
    }
    return state;
};
