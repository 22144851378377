import { APPLY_PATHWAY_DECISION_ACTION } from 'actions/pathwayBlueprint/applyPathwayDecision';
import { ADD_USER_PROFILE_PICTURE_ACTION } from 'actions/rhFile/addUserProfilePicture';
import { GET_SURVEY_INFO_FOR_USER_ACTION } from 'actions/survey/getSurveyInfoForUser';
import { GET_SURVEY_USER_PROFILE_ACTION } from 'actions/survey/getSurveyUserProfile';
import { SUBMIT_SURVEY_ACTION } from 'actions/survey/submitSurvey';
import { EDIT_TEAM_ADDRESS_ACTION } from 'actions/team/editTeamAddress';
import { GET_TEAM_PROFILE_ACTION } from 'actions/team/getTeamProfile';
import { PATCH_TEAM_ACTION } from 'actions/team/patchTeam';
import { SELECT_ANCILLARY_BENEFITS_ACTION } from 'actions/teamBenefit/selectAncillaryBenefits';
import {
    EDIT_USER_HOME_ADDRESS_ACTION,
    EDIT_USER_MAILING_ADDRESS_ACTION,
} from 'actions/user/addOrEditUserAddress';
import { REMOVE_USER_RELATIONSHIP_ACTION } from 'actions/user/deleteUserRelationship';
import { EDIT_USER_ACTION } from 'actions/user/editUser';
import { GET_HOUSEHOLD_ACTION } from 'actions/user/getHousehold';
import { GET_USER_NOTES_ACTION } from 'actions/user/getUserNotes';
import { GET_USER_PROFILE_ACTION } from 'actions/user/getUserProfile';
import { GET_YEARLY_USER_INFO_ACTION } from 'actions/user/getYearlyUserInfo';
import { PATCH_MEMBER_QUOTE_INFO_ACTION } from 'actions/user/patchMemberQuoteInfo';
import { PATCH_MEMBER_VERIFIED_INFO_ACTION } from 'actions/user/patchMemberVerifiedInfo';
import { PATCH_USER_ACTION } from 'actions/user/patchUser';
import { PATCH_YEARLY_USER_INFO_ACTION } from 'actions/user/patchYearlyUserInfo';
import { SEND_RENEWAL_EMAIL_ACTION } from 'actions/user/sendRenewalEmail';
import { SET_ENROLLMENT_STATUS_TO_ENROLLED_ACTION } from 'actions/user/setEnrollmentStatusToEnrolled';
import {
    ADD_USER_RELATIONSHIP_ACTION,
    EDIT_USER_RELATIONSHIP_ACTION,
} from 'actions/user/setUserRelationship';
import { SET_USER_STATUS_ACTION } from 'actions/user/setUserStatus';
import { UPDATE_INCOME_VERIFIED_DATE_ACTION } from 'actions/user/updateIncomeVerifiedDate';
import { UserInfoStatus } from 'api/generated/enums';
import {
    Address,
    IHousehold,
    IMemberVerifiedInfo,
    ISurveyInfo,
    ITeamProfile,
    IUser,
    IUserProfile,
    MemberQuoteInfo,
    MemberVerifiedInfo,
    Note,
    Team,
    User,
    UserUserRelationship,
    YearlyUserInfoDto,
} from 'api/generated/models';

type stateType = {
    loaded: boolean;
    teamProfile: ITeamProfile;
    userProfile: IUserProfile;
};
const initialState: stateType = {
    loaded: false,
    teamProfile: {},
    userProfile: ({ user: {} } as unknown) as IUserProfile,
};

type ICurrentDataType =
    | Address
    | IHousehold
    | IMemberVerifiedInfo
    | ISurveyInfo
    | ITeamProfile
    | IUser
    | IUserProfile
    | MemberQuoteInfo
    | Note[]
    | Team
    | UserUserRelationship
    | YearlyUserInfoDto
    | string;

export const current = (
    state = initialState,
    action: {
        data: ICurrentDataType;
        isCurrent?: boolean;
        type: string;
    }
): stateType => {
    if (action.isCurrent) {
        switch (action.type) {
            case GET_SURVEY_INFO_FOR_USER_ACTION.success:
            case GET_SURVEY_USER_PROFILE_ACTION.success: {
                const data = action.data as ISurveyInfo;
                return {
                    ...state,
                    teamProfile: { team: data.team },
                    userProfile: data.userProfile as IUserProfile,
                };
            }
            case UPDATE_INCOME_VERIFIED_DATE_ACTION.success:
                return {
                    ...state,
                    userProfile: {
                        ...state.userProfile,
                        memberVerifiedInfo: new MemberVerifiedInfo({
                            ...(state.userProfile.memberVerifiedInfo as IMemberVerifiedInfo),
                            incomeVerifiedDate: (action.data as IMemberVerifiedInfo)
                                .incomeVerifiedDate,
                        }),
                    },
                };
            case GET_HOUSEHOLD_ACTION.success:
                return {
                    ...state,
                    userProfile: (action.data as IHousehold).userProfile as IUserProfile,
                };
            case GET_USER_PROFILE_ACTION.success:
            case SEND_RENEWAL_EMAIL_ACTION.success:
                return {
                    ...state,
                    loaded: true,
                    userProfile: action.data as IUserProfile,
                };
            case GET_TEAM_PROFILE_ACTION.success:
                return {
                    ...state,
                    loaded: true,
                    teamProfile: action.data as ITeamProfile,
                };
            case EDIT_USER_HOME_ADDRESS_ACTION.success:
                return {
                    ...state,
                    userProfile: {
                        ...state.userProfile,
                        address: action.data as Address,
                    },
                };
            case EDIT_USER_MAILING_ADDRESS_ACTION.success:
                return {
                    ...state,
                    userProfile: {
                        ...state.userProfile,
                        mailingAddress: action.data as Address,
                    },
                };
            case PATCH_USER_ACTION.success:
            case SET_USER_STATUS_ACTION.success:
                return {
                    ...state,
                    userProfile: {
                        ...state.userProfile,
                        user: new User({
                            ...(action.data as IUser),
                            userInfoStatus: state.userProfile.user
                                ?.userInfoStatus as UserInfoStatus,
                        }),
                    },
                };
            case PATCH_MEMBER_QUOTE_INFO_ACTION.success:
                return {
                    ...state,
                    userProfile: {
                        ...state.userProfile,
                        memberQuoteInfo: action.data as MemberQuoteInfo,
                    },
                };
            case PATCH_MEMBER_VERIFIED_INFO_ACTION.success:
                return {
                    ...state,
                    userProfile: {
                        ...state.userProfile,
                        memberVerifiedInfo: action.data as MemberVerifiedInfo,
                    },
                };
            case EDIT_USER_ACTION.success:
                return {
                    ...state,
                    userProfile: {
                        ...state.userProfile,
                        ...(action.data as IUserProfile),
                    },
                };
            case EDIT_TEAM_ADDRESS_ACTION.success:
                return {
                    ...state,
                    teamProfile: {
                        ...state.teamProfile,
                        address: action.data as Address,
                    },
                };
            case PATCH_TEAM_ACTION.success:
                return {
                    ...state,
                    teamProfile: {
                        ...state.teamProfile,
                        team: action.data as Team,
                    },
                };
            case GET_USER_NOTES_ACTION.success:
                return {
                    ...state,
                    userProfile: {
                        ...state.userProfile,
                        userNotes: action.data as Note[],
                    },
                };
            case APPLY_PATHWAY_DECISION_ACTION.success:
            case SUBMIT_SURVEY_ACTION.success:
                return {
                    ...state,
                    userProfile: action.data as IUserProfile,
                };
            case ADD_USER_PROFILE_PICTURE_ACTION.success:
                return {
                    ...state,
                    userProfile: {
                        ...state.userProfile,
                        user: new User({
                            ...(state.userProfile.user as IUser),
                            profilePictureRhFileId: action.data as string,
                        }),
                    },
                };
            case REMOVE_USER_RELATIONSHIP_ACTION.success:
                return {
                    ...state,
                    userProfile: {
                        ...state.userProfile,
                        userUserRelationships: state.userProfile.userUserRelationships?.filter(
                            (relationship) =>
                                relationship.id !== (action.data as UserUserRelationship).id
                        ),
                    },
                };
            case EDIT_USER_RELATIONSHIP_ACTION.success:
                return {
                    ...state,
                    userProfile: {
                        ...state.userProfile,
                        userUserRelationships: state.userProfile.userUserRelationships?.map((x) => {
                            const data = action.data as UserUserRelationship;
                            if (x.id === data.id) {
                                return data;
                            }
                            return x;
                        }),
                    },
                };
            case ADD_USER_RELATIONSHIP_ACTION.success:
                return {
                    ...state,
                    userProfile: {
                        ...state.userProfile,
                        userUserRelationships: [
                            ...(state.userProfile.userUserRelationships ?? []),
                            action.data as UserUserRelationship,
                        ],
                    },
                };
            case PATCH_YEARLY_USER_INFO_ACTION.success:
            case SET_ENROLLMENT_STATUS_TO_ENROLLED_ACTION.success:
            case SELECT_ANCILLARY_BENEFITS_ACTION.success:
            case GET_YEARLY_USER_INFO_ACTION:
                return {
                    ...state,
                    userProfile: {
                        ...state.userProfile,
                        yearlyUserInfo: action.data as YearlyUserInfoDto,
                    },
                };
        }
    }
    return state;
};
